<template>
  <div class="upload-excel">
    <span
      class="count-uploaded-items"
      v-if="showCount && count != null"
      @click="
        count = null;
        $emit('searchWithNumDossier', null);
      "
      >{{ count }}</span
    >
    <div
      class="chargement chargement-loading-icon loader-uploaded-items"
      v-if="loader"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      class="container"
      @click="
        count = null;
        $emit('searchWithNumDossier', null);
      "
    >
      <div class="file-wrapper" :class="{ 'succes-file-excel': value != null }">
        <input
          type="file"
          name="file-input"
          id="uploadInput"
          @change="handleFileInput"
          :disabled="showCount && loader"
        />
        <div class="upload_label">
          <font-awesome-icon icon="cloud-upload-alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    value: { required: true },
    fetchFuntion: { resuired: false },
    showCount: { default: false }
  },
  data() {
    return { file: null, count: null, loader: false };
  },
  methods: {
    ...mapActions(['uploadFileExcelNumDossier']),
    async handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        this.file = f;
      });
      document.getElementById('uploadInput').value = '';
      if (!this.fetchFuntion) {
        const response = await this.uploadFileExcelNumDossier(this.file);
        if (response.succes) {
          this.$emit('searchWithNumDossier', response.data);
        }
      } else {
        this.loader = true;
        const response = await this.fetchFuntion(this.file);
        if (response.succes) {
          this.count = response.data.length;
          this.$emit('searchWithNumDossier', response.data);
        } else {
          this.$alert('', response.error, 'erreur');
        }
        this.loader = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-excel {
  position: relative;
  .container {
    height: 30px;
    width: 59px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px solid #4d4bac;
      background-color: #4d4bac;
      color: #fff;
      border-radius: 16px;
      box-shadow: 1px 2px 5px 5px #f5f5f5;
      padding: 3px;
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 16px;
        position: relative;
      }
    }
    .succes-file-excel {
      border: 1px solid #6fc76557;
      background-color: #0e5e069c;
      font-size: 9px;
    }
  }
  .loader-uploaded-items {
    position: absolute;
    z-index: 100;
    right: 18px;
    top: 3px;
    color: white;
  }
  .count-uploaded-items {
    position: absolute;
    right: 12px;
    background: #f7c04a;
    border-radius: 50px;
    font-size: 10px;
    line-height: 13px;
    color: white;
    padding: 0px 3px;
    top: -4px;
    z-index: 100;
    cursor: pointer;
  }
}
</style>
