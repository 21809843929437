<template>
  <div class="refresh-fact">
    <b-button
      v-if="checkPermission('REFFTHAF')"
      size="sm"
      class="button-export-style  ml-2"
      @click="showConfirmAlert"
      title="Actualiser"
    >
      <img src="@/assets/arrows_rotate.png" height="20" width="25"
    /></b-button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    filter: { required: true }
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  methods: {
    ...mapActions(['refreshFactureCdg']),
    resetModal() {
      this.error = null;
      this.loading = false;
    },
    showConfirmAlert() {
      let htmlMessage = '';
      let title = this.dataToUse.length
        ? "Êtes-vous sur d'actualiser les facture(s) suivantes"
        : "Êtes-vous sur d'actualiser les facture(s) selon les filters";
      if (this.dataToUse.length) {
        htmlMessage =
          "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;text-align: left;' class='ModalScrol'><div> ";
        for (let i = 0; i < this.dataToUse.length; i++) {
          htmlMessage = htmlMessage + '<li>' + this.dataToUse[i].num + '</li>';
        }
        htmlMessage = htmlMessage + '</div></div>';
      }

      this.$swal
        .fire({
          background: 'rgb(245 245 252)',
          title: title,
          type: 'warning',
          icon: '',
          locale: 'fr',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          showCancelButton: true,
          html: htmlMessage,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            if (this.dataToUse.length) {
              for (let i = 0; i < this.dataToUse.length; i++) {
                bodyFormData.append('ids[' + [i] + ']', this.dataToUse[i].id);
              }
              bodyFormData.append('famille', this.filter.famille);
            } else {
              Object.keys(this.filter).forEach(key => {
                let value = this.filter[key];
                if (typeof value == 'object') {
                  value.forEach((element, index) => {
                    bodyFormData.append(
                      key + '[' + index + ']',
                      element.value ? element.value : element
                    );
                  });
                } else {
                  bodyFormData.append(key, value);
                }
              });
            }
            this.$emit('refreshLoader', true);
            const response = await this.refreshFactureCdg(bodyFormData);
            if (response.succes) {
              let html = '';
              if (response.data.successFactures.length) {
                html =
                  html +
                  "<div style='text-align-last: left;margin-top:24px;'><b style='text-align: left;'>Facture actualisé (" +
                  response.data.successFactures.length +
                  ") : </b> </div></br><div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;text-align: left;' class='ModalScrol'><div> ";
                for (let i = 0; i < response.data.successFactures.length; i++) {
                  html =
                    html + '<li>' + response.data.successFactures[i] + '</li>';
                }
                html = html + '</div></div>';
              }
              if (response.data.comptabiliseFactures.length) {
                html =
                  html +
                  "<div style='text-align-last: left;margin-top:5px;'><b>Facture actualisé</b></div> </br><div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";
                for (
                  let i = 0;
                  i < response.data.comptabiliseFactures.length;
                  i++
                ) {
                  html =
                    html +
                    '<li>' +
                    response.data.comptabiliseFactures[i] +
                    '</li>';
                }
                html = html + '</div></div>';
              }
              this.$emit('refreshLoader', false);

              this.$swal({
                title: '<strong>Résultat : </strong>',
                html: html,
                showCloseButton: true,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'OK'
              });
            } else {
              this.$emit('refreshLoader', false);
              this.$alert('', 'Une erreur est servenue', 'error');
            }
          }
        });
    }
  },
  computed: {
    ...mapGetters(['checkPermission'])
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  padding: 5px 1px;
  background-color: #f2bb16;
  &:hover,
  &:focus {
    background-color: #cd9317;
  }
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
