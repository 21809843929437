<template>
  <div class="box-label-champ mr-2">
    <div class="label-box-style " :class="classLabel">
      <span class="title-tabel" v-if="label">{{ label }}</span>
    </div>
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {
  props: ['label', 'classLabel']
};
</script>

<style lang="scss" scoped>
.title-tabel {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  color: #ffffff;
  white-space: nowrap;
}
</style>
