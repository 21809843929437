<template>
  <div class="refresh-facture-interface">
    <div class="loader-refresh" v-if="refreshLoader">
      <Half-circle-spinner
        :animation-duration="1000"
        :size="50"
        :color="'#4d4bac'"
        class="loeder"
      />
    </div>
    <div class="entete">
      <div class="box-all-filter">
        <filter-component
          v-if="checkPermission('REFFTHFDD')"
          label="Date début"
          classLabel="label-box-style w-63-px"
        >
          <template v-slot:body>
            <date-picker
              v-model="date_debut"
              value-type="format"
              :disabled-date="disabledStartDate"
              @change="handleFilter(true)"
              type="date"
              required
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter mt-2 mb-2"
            ></date-picker
          ></template>
        </filter-component>
        <filter-component
          label="Date fin"
          classLabel="label-box-style w-63-px"
          v-if="checkPermission('REFFTHFDF')"
        >
          <template v-slot:body>
            <date-picker
              v-model="date_fin"
              :disabled-date="disabledEndDate"
              @change="handleFilter(true)"
              value-type="format"
              type="date"
              required
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter mt-2 mb-2"
            ></date-picker
          ></template>
        </filter-component>
        <input-file-excel-num-dossier
          v-show="true"
          :value="nums"
          @searchWithNumDossier="searchWithMultipleFacture"
          :fetchFuntion="fecthFactureNumbersInExcelFile"
          :showCount="true"
        />
        <div class="error-message ml-2 mr-2">
          <div v-if="errorFactures" class="error">
            <ul v-if="Array.isArray(errorFactures)" class="mb-0">
              <li v-for="(e, index) in errorFactures" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ errorFactures }}</div>
          </div>
        </div>
        <div
          v-if="loadingFactureComptabilite"
          class="chargement chargement-loading-icon m-0"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <b-popover
            triggers="focus"
            target="popover-target-1"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <filter-component
              label="Société"
              classLabel="w-100-px"
              v-if="checkPermission('REFFTHFV')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="vendeur"
                  :multiple="true"
                  @input="handleFilter"
                  label="text"
                  :options="computedGetVendeurs"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Société'
                          : values.length > 1
                          ? ' Sociétés'
                          : ''
                      }}
                    </span>
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Client"
              classLabel="w-100-px
"
              v-if="checkPermission('REFFTHFA')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="acheteur"
                  @input="handleFilter"
                  :multiple="true"
                  :options="computedGetAcheteurs"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Client'
                          : values.length > 1
                          ? ' Clients'
                          : ''
                      }}</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Famille"
              classLabel="w-100-px"
              v-if="checkPermission('REFFTHFF')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="famille"
                  @input="handleFilter"
                  :multiple="true"
                  :options="computedListFamille"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Famille</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Familles</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Catégorie"
              classLabel="w-100-px
"
              v-if="checkPermission('REFFTHFC')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="categorie"
                  :multiple="true"
                  @input="handleFilter"
                  :options="computedGetCategories"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Catégorie</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Catégories</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Statut"
              classLabel="w-100-px
"
              v-if="checkPermission('REFFTHFS')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="statut"
                  :options="ListStatut"
                  @input="handleFilter"
                  :multiple="true"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter libres mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Statut</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Statuts</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Type"
              classLabel="w-100-px
"
              v-if="checkPermission('REFFTHFT')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="type"
                  :options="ListType"
                  @input="handleFilter"
                  :multiple="true"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter libres mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Type</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Types</span
                    >
                  </template>
                </multiselect>
              </template>
            </filter-component>
            <filter-component
              classLabel="w-100-px"
              v-if="checkPermission('REFFTHFCO')"
              label="Comptabilisé"
            >
              <template v-slot:body>
                <b-form-select
                  class="
                    b-form-select-new-style b-form-select-raduis-border
                    mt-2
                    mb-2
                  "
                  v-model="comptabilite"
                  :options="computedComptabilisé"
                  text-field="text"
                  value-field="value"
                  @change="handleFilter"
                ></b-form-select
              ></template>
            </filter-component>
            <filter-component
              label="Numéro de lot"
              classLabel="w-100-px"
              v-if="checkPermission('REFFTHFL')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="mail_lot"
                  :multiple="true"
                  @input="handleFilter"
                  :options="computedLot"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }}
                    </span>
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} lots</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
          </b-popover>
          <search
            v-if="checkPermission('REFFTHR')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            :dynamic="false"
            placeHolder="Num dossier"
          ></search>
          <refresh
            :dataToUse="computedCheckFactureSelected"
            :filter="computedFilter"
            @refreshLoader="handlerefreshLoader"
          />
          <b-button
            size="sm"
            class="button-export-style ml-2 mr-2"
            title="Filter"
            id="popover-target-1"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
        </div>
      </div>
    </div>
    <!-- block filtre -->
    <div>
      <div
        v-if="
          date_debut !== null ||
            date_fin !== null ||
            searchValue !== null ||
            comptabilite !== null
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont : </span>
        <span class="item-filter-style" v-if="date_debut !== null"
          >Date début : {{ date_debut }}</span
        >
        <span class="item-filter-style" v-if="date_fin !== null"
          >Date fin : {{ date_fin }}</span
        >
        <span v-if="searchValue !== null" class="item-filter-style"
          >Recherche : {{ searchValue }}</span
        >
        <span v-if="comptabilite !== null" class="item-filter-style"
          >Comptabilisé : {{ comptabilite }}</span
        >
      </div>
    </div>
    <!--End block filtre -->
    <div class="chip-filters-simulation">
      <div v-if="vendeur && vendeur.length" class="block-filter">
        <span class="title-block-chip">Société : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="vendeur"
          @input="handleFilter"
        >
          <vs-chip
            :key="vd.text + 'master-filiale'"
            @click="remove(vd, 'vendeur', 'handleFilter')"
            v-for="vd in vendeur"
            closable
          >
            {{ vd.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="acheteur && acheteur.length" class="block-filter">
        <span class="title-block-chip">Client : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="acheteur"
          @input="handleFilter"
        >
          <vs-chip
            :key="acht.text + 'acheteur'"
            @click="remove(acht, 'acheteur', 'handleFilter')"
            v-for="acht in acheteur"
            closable
          >
            {{ acht.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="famille && famille.length" class="block-filter">
        <span class="title-block-chip">Famille : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="famille"
          @input="handleFilter"
        >
          <vs-chip
            :key="fam.text + 'famille'"
            @click="remove(fam, 'famille', 'handleFilter')"
            v-for="fam in famille"
            closable
          >
            {{ fam.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="categorie && categorie.length" class="block-filter">
        <span class="title-block-chip">Catégorie : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="categorie"
          @input="handleFilter"
        >
          <vs-chip
            :key="cat.text + 'cat'"
            @click="remove(cat, 'categorie', 'handleFilter')"
            v-for="cat in categorie"
            closable
          >
            {{ cat.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="statut && statut.length" class="block-filter">
        <span class="title-block-chip">Statut : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="statut"
          @input="handleFilter"
        >
          <vs-chip
            :key="st.text + 'cat'"
            @click="remove(st, 'statut', 'handleFilter')"
            v-for="st in statut"
            closable
          >
            {{ st.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="type && type.length" class="block-filter">
        <span class="title-block-chip">Type : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="type"
          @input="handleFilter"
        >
          <vs-chip
            :key="tp.text + 'type'"
            @click="remove(tp, 'type', 'handleFilter')"
            v-for="tp in type"
            closable
          >
            {{ tp.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="mail_lot && mail_lot.length" class="block-filter">
        <span class="title-block-chip">Lot : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="mail_lot"
          @input="handleFilter"
        >
          <vs-chip
            :key="tp + 'mail_lot'"
            @click="remove(tp, 'mail_lot', 'handleFilter')"
            v-for="tp in mail_lot"
            closable
          >
            {{ tp }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <div class="body-box-rapport" :style="sizeBlockTable">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="
            custom-table-style
            customTableRefreshFactureTh
            table-header
          "
          bordered
          :style="sizeTable"
          :items="factures"
          :fields="computedFields"
          sticky-header
          hover
          responsive
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                @change="checkAllFunction"
                class="check-th"
                :value="true"
                :unchecked-value="false"
                v-model="checkAll"
              >
              </b-form-checkbox>
            </div>
            <div
              v-else
              :class="{
                'd-flex justify-content-center align-items-center':
                  data.field.isSortable === true,
                'd-flex justify-content-center':
                  data.field.isSortable === false,
                'underline-selected-colomn': data.field.key === column
              }"
            >
              {{ data.field.label }}

              <font-awesome-icon
                v-if="data.field.isSortable === true"
                @click="listOrder(data.field.key, 'ASC')"
                icon="arrow-up"
                :class="
                  data.field.key === column && order === 'ASC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
              {{ ' ' }}
              <font-awesome-icon
                @click="listOrder(data.field.key, 'DESC')"
                v-if="data.field.isSortable === true"
                icon="arrow-down"
                :class="
                  data.field.key === column && order === 'DESC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkRows(data.item)"
              name="flavour-1"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(date_creation)="data">
            {{ data.item.date_creation | formateDate }}
          </template>
          <template v-slot:cell(details)="data">
            <button
              class="btn btn-anomalie"
              v-if="data.item.factureAvoir.length > 0"
              @click="data.toggleDetails"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les avoirs"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masquer les avoirs"
              />
            </button>
          </template>
          <template #row-details="data">
            <span v-for="item in data.item.factureAvoir" :key="item.id">
              <td class="col-check-obligee">
                <div>
                  <b-form-checkbox
                    v-model="item.checkAvoir"
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td class="col-plus-obligee">
                <div
                  class="block-service-style d-flex justify-content-center"
                ></div>
              </td>
              <td class="item-details-style">
                <div>
                  {{ item.vendeur }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.type }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.famille }}
                </div>
              </td>
              <td class="item-details-style">
                <div id="info-wrap" class="d-flex justify-content-center">
                  <div class="description-column">
                    {{ item.num }}
                  </div>
                  <div
                    class="info-column-refresh"
                    v-if="checkPermission('REFFTHTTFA')"
                  >
                    <font-awesome-icon
                      icon="arrow-alt-circle-down"
                      @click.prevent="downloadFacture(item.id)"
                      class="icon-style-color mr-1 cursor-pointer ml-2"
                      title="Télécharger la facture"
                    />
                    <font-awesome-icon
                      icon="eye"
                      @click.prevent="handleShowFacture(item.id)"
                      title="visualiser la facture"
                      class="icon-style-color mr-1 cursor-pointer"
                    />
                  </div>
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_creation | formateDate }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.acheteur }}
                </div>
              </td>

              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.etat }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_paiement | formateDate }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{
                    item.categorie && item.categorie.nom_categorie
                      ? item.categorie.nom_categorie
                      : '-'
                  }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_net_ht }} {{ item.devise }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_tva }}
                </div>
              </td>
              <td class="item-details-style">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_ttc }} {{ item.devise }}
                </div>
              </td>
              <td class="item-details-style">
                <div
                  class="block-service-style d-flex justify-content-center m-1"
                >
                  {{ data.item.comptabilise }}
                </div>
              </td>
              <td class="item-details-style">
                <div
                  class="block-service-style d-flex justify-content-center m-1"
                >
                  {{ data.item.mail_lot }}
                </div>
              </td>
            </span>
          </template>
          <template v-slot:cell(num)="data">
            <div id="info-wrap" class="d-flex justify-content-center">
              <div class="description-column mr-1">
                {{ data.item.num }}
              </div>
              <div
                class="info-column-refresh"
                v-if="checkPermission('REFFTHTFACT')"
              >
                <font-awesome-icon
                  icon="arrow-alt-circle-down"
                  @click.prevent="downloadFacture(data.item.id)"
                  class="icon-style-color mr-1 cursor-pointer ml-2"
                  title="Télécharger la facture"
                />
                <font-awesome-icon
                  icon="eye"
                  @click.prevent="handleShowFacture(data.item.id)"
                  title="visualiser la facture"
                  class="icon-style-color mr-1 cursor-pointer"
                />
              </div>
            </div>
          </template>
          <template v-slot:cell(comptabilise)="data">
            {{ data.item.comptabilise == null ? '--' : data.item.comptabilise }}
          </template>
        </b-table>
      </div>
    </div>
    <div class="footer-style mt-2">
      <b-pagination
        v-model="page"
        :per-page="perPage"
        :total-rows="getRows"
        aria-controls="my-table"
        pills
        align="center"
        size="sm"
        @change="pagination"
        class="pagination-style"
      ></b-pagination>

      <div class="per-page-element-style">
        <div class="box-label-champ">
          <div class="label-box-style">
            <span class="title-tabel">Eléments par page</span>
          </div>
        </div>
        <b-form-select
          v-model="perPage"
          :options="perPageList"
          @change="handleFilter"
          class="b-form-select-new-style bg-select"
        ></b-form-select>
      </div>
      <div
        class="style-somme-th-ttc"
        v-if="computedTotalHt || computedTotalTtc"
      >
        <p class="m-2">
          Total NET HT:
          {{ computedTotalHt.toFixed(2) }}€ {{ '   ' }}Total TTC:

          {{ computedTotalTtc.toFixed(2) }} €
        </p>
      </div>
    </div>

    <b-modal
      ref="visualisationFactureModal"
      id="visualisationFactureModal"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap modal-dialog-visualisation"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Afficher Facture</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('visualisationFactureModal')"
          title="Fermer"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <iframe
            height="750"
            width="700"
            :src="factureToShow + '#toolbar=0'"
            scrolling="no"
          ></iframe>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import filterComponent from '@/views/ThProject/frais/component/filterComponent.vue';
import Refresh from './component/refresh.vue';
import InputFileExcelNumDossier from '../ThProject/componentRapportTh/inputFileExcelNumDossier.vue';
export default {
  data() {
    return {
      nums: null,
      refreshLoader: false,
      date_debut: null,
      date_fin: null,
      acheteur: [],
      famille: [],
      statut: [],
      type: [],
      vendeur: [],
      categorie: [],
      checkAll: false,
      comptabilite: null,
      mail_lot: [],
      fam: 'all',
      searchValue: null,
      page: 1,
      perPage: 100,
      column: 'id',
      order: 'DESC',
      factureToShow: null,
      ListType: [
        { value: 'Facture', text: 'Facture' },
        { value: 'Facture proforma', text: 'Facture proforma' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ],
      perPageList: [
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 300, text: 300 }
      ],
      ListStatut: [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'A payer', text: 'A payer' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'errorFactures',
      'loadingFactureComptabilite',
      'checkPermission',
      'factures',
      'getRows',
      'familles',
      'getSettingFilialesTh',
      'getFiliaeOfResponsable',
      'getLotsCompta',
      'typesCompta',
      'getAllCategories'
    ]),
    computedFilter() {
      let params = { famille: this.fam };

      if (this.date_debut != null) {
        params.date_debut = this.date_debut;
      }
      if (this.date_fin != null) {
        params.date_fin = this.date_fin;
      }
      if (this.statut.length) {
        params.etat = this.statut;
      }
      if (this.type.length) {
        params.type = this.type;
      }
      if (this.famille.length) {
        params.family = this.famille;
      }
      if (this.vendeur.length) {
        params.vendeur_id = this.vendeur;
      }
      if (this.acheteur.length) {
        params.acheteur_id = this.acheteur;
      }
      if (this.categorie.length) {
        params.categorie_id = this.categorie;
      }
      if (this.mail_lot.length) {
        params.mail_lot = this.mail_lot;
      }
      if (this.comptabilite != null) {
        params.comptabilite = this.comptabilite;
      }
      if (this.searchValue != null) {
        params.num = this.searchValue;
      }

      return params;
    },
    computedComptabilisé() {
      let types = [];
      types.push({
        value: null,
        text: 'Tous'
      });
      for (let i = 0; i < this.typesCompta.length; i++) {
        types.push({
          value: this.typesCompta[i],
          text: this.typesCompta[i]
        });
      }

      return types;
    },
    computedLot() {
      return this.getLotsCompta;
    },
    computedListFamille() {
      let familles = [];
      for (let i = 0; i < this.familles.length; i++) {
        familles.push({
          value: this.familles[i],
          text: this.familles[i]
        });
      }
      return familles;
    },
    computedGetAcheteurs() {
      let acheteurNames = this.getSettingFilialesTh.map(item => {
        return { value: item.id, text: item.name };
      });
      return acheteurNames;
    },
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedGetCategories() {
      let categoriesNames = this.getAllCategories.map(item => {
        return { value: item.id, text: item.nom_categorie };
      });
      return categoriesNames;
    },
    computedCheckRowsSelected() {
      return this.factures.filter(i => i.check == true);
    },
    computedCheckFactureSelected() {
      let tab = [];
      this.factures.forEach(fact => {
        if (fact.check == true) {
          tab.push(fact);
        }
        fact.factureAvoir.forEach(a => {
          if (a.checkAvoir == true) {
            tab.push(a);
          }
        });
      });
      return tab;
    },
    computedTotalHt() {
      let sum = 0;
      let sumAvoir = 0;
      this.computedCheckRowsSelected.map(item => {
        sum += item.net_ht;
        for (let i = 0; i < item.factureAvoir.length; i++) {
          sumAvoir += item.factureAvoir[i].total_ht;
        }
      });
      return Math.round(sum * 100) / 100 - Math.round(sumAvoir * 100) / 100;
    },
    computedCheckChipFilter() {
      if (
        this.vendeur.length > 0 ||
        this.acheteur.length > 0 ||
        this.famille.length > 0 ||
        this.categorie.length > 0 ||
        this.statut.length > 0 ||
        this.type.length > 0 ||
        this.mail_lot.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedTotalTtc() {
      let sum = 0;
      let sumAvoir = 0;
      this.computedCheckRowsSelected.map(facture => {
        sum += facture.ttc;
        for (let i = 0; i < facture.factureAvoir.length; i++) {
          sumAvoir += parseFloat(
            facture.factureAvoir[i].total_ttc.replace(' ', '')
          );
        }
      });
      return Math.round(sum * 100) / 100 - Math.round(sumAvoir * 100) / 100;
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 199 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 245 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true
        },
        {
          key: 'details',
          label: '',
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          show: true
        },
        {
          key: 'Vendeur',
          label: 'SOCIÉTÉ',
          isSortable: true,
          show: this.checkPermission('REFFTHACV')
        },
        {
          key: 'type',
          label: 'TYPE',
          isSortable: true,
          show: this.checkPermission('REFFTHACT')
        },
        {
          key: 'famille',
          label: 'FAMILLE',
          isSortable: true,
          show: this.checkPermission('REFFTHACF')
        },
        {
          key: 'num',
          label: 'NUMERO',
          isSortable: true,
          show: this.checkPermission('REFFTHACN')
        },

        {
          key: 'date_creation',
          label: 'DATE DE CRÉATION',
          isSortable: true,
          show: this.checkPermission('REFFTHACDDC')
        },
        {
          key: 'Acheteur',
          label: 'CLIENT',
          isSortable: true,
          show: this.checkPermission('REFFTHACA')
        },
        {
          key: 'etat',
          label: 'ETAT',
          isSortable: true,
          show: this.checkPermission('REFFTHACE')
        },
        {
          key: 'date_paiement',
          label: 'DATE DE PAIEMENT',
          isSortable: true,
          show: this.checkPermission('REFFTHACDDP')
        },
        {
          key: 'categorie',
          label: 'CATEGORIE',
          isSortable: true,
          show: this.checkPermission('REFFTHAC')
        },
        {
          key: 'total_net_ht',
          label: 'NET HT',
          isSortable: true,
          show: this.checkPermission('REFFTHACNETHT')
        },
        {
          key: 'total_tva',
          label: 'TVA',
          isSortable: true,
          show: this.checkPermission('REFFTHACTVA')
        },
        {
          key: 'total_ttc',
          label: 'TOTAL TTC',
          isSortable: true,
          show: this.checkPermission('REFFTHACTTC')
        },
        {
          key: 'comptabilise',
          label: 'Comptabilisé',
          isSortable: true,
          show: this.checkPermission('REFFTHACCOM')
        },
        {
          key: 'mail_lot',
          label: 'Numéro de lot',
          isSortable: true,
          show: this.checkPermission('REFFTHACNL')
        }
      ];

      return fields.filter(field => field.show == true);
    }
  },
  methods: {
    ...mapActions([
      'getFactures',
      'getLotCompta',
      'getSettingFilialeTh',
      'getCategoriesFactureLibre',
      'getFamilles',
      'getFilialsOfConnectedResponsable',
      'getTypeOfCompta',
      'downloadPDF',
      'fecthFactureNumbersInExcelFile',
      'displayFact'
    ]),
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },
    handlerefreshLoader(data) {
      this.refreshLoader = data;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.factureToShow = null;
    },
    async handleShowFacture(id) {
      this.$refs['visualisationFactureModal'].show();
      const response = await this.displayFact(id);
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    downloadFacture(id) {
      this.downloadPDF({ id: id });
    },
    handleFetchLot() {
      this.getLotCompta({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    checkAllFunction() {
      this.factures.map(facture => {
        facture.check = this.checkAll;
        facture.factureAvoir.map(a => (a.checkAvoir = this.checkAll));
      });
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    changeSearchValue(event) {
      this.searchValue = event;
      this.handleFilter();
    },
    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    async listOrder(col, ord) {
      this.column = col;
      this.order = ord;
      this.handleFilter();
    },
    async handleFilter(fetchFilter) {
      if (fetchFilter) {
        this.handleFetchLot();
      }
      this.resetCheck();
      this.setLocalStorageRefreshFacture();
      await this.getFactures({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        etat: this.statut,
        type: this.type,
        family: this.famille,
        vendeur_id: this.vendeur,
        acheteur_id: this.acheteur,
        categorie_id: this.categorie,
        per_page: this.perPage,
        page: this.page,
        order: this.order,
        column: this.column,
        famille: 'all',
        num: this.searchValue,
        comptabilite: this.comptabilite,
        mail_lot: this.mail_lot,
        nums: this.nums
      });
    },
    setLocalStorageRefreshFacture() {
      localStorage.setItem(
        'refresh-facture-cdg-th',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          etat: this.statut,
          type: this.type,
          family: this.famille,
          vendeur_id: this.vendeur,
          acheteur_id: this.acheteur,
          categorie_id: this.categorie,
          per_page: this.perPage,
          page: this.page,
          order: this.order,
          column: this.column,
          famille: this.fam,
          num: this.searchValue,
          comptabilite: this.comptabilite
        })
      );
    },
    resetCheck() {
      this.checkAll = false;
      this?.factures?.map(facture => (facture.check = false));
    },
    checkRows(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.factureAvoir.map(i => (i.checkAvoir = item.check));
    }
  },
  mounted() {
    if (localStorage.getItem('efresh-facture-cdg-th')) {
      this.date_debut = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).date_debut;
      this.date_fin = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).date_fin;
      this.vendeur = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).vendeur_id;
      this.acheteur = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).acheteur_id;
      this.categorie = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).categorie_id;
      this.famille = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).family;
      this.type = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).type;
      this.statut = JSON.parse(localStorage.getItem('efresh-facture-cdg-th'))
        .etat
        ? JSON.parse(localStorage.getItem('efresh-facture-cdg-th')).etat
        : [];
      this.searchValue = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).num;
      this.comptabilite = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).comptabilite;
      this.order = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).order;
      this.column = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).column;
      this.fam = JSON.parse(
        localStorage.getItem('efresh-facture-cdg-th')
      ).famille;
    } else {
      this.date_fin = moment(new Date()).format('YYYY-MM-DD');
      this.date_debut = moment(this.date_fin)
        .subtract(12, 'months')
        .format('YYYY-MM-DD');

      this.setLocalStorageRefreshFacture();
    }
    this.handleFilter(true);
    this.getFamilles();
    this.getTypeOfCompta();
    this.getCategoriesFactureLibre();
    this.getFilialsOfConnectedResponsable();
    this.getSettingFilialeTh();
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
      }
    }
  },
  components: {
    filterComponent,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    Refresh,
    InputFileExcelNumDossier,
    Card: () => import('@/views/component/card.vue')
  }
};
</script>

<style scoped lang="scss">
.refresh-facture-interface {
  padding-top: 20px;
  padding-left: 15px;
  width: 99%;
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 188px);
    .customTableRefreshFactureTh {
      height: 100%;
      max-height: 100%;
      margin-bottom: 0px;
    }
  }
  .show-coef-button {
    background-color: green;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .info-column-refresh {
    display: flex;
    align-items: center;
  }
  .show-montant-button {
    background-color: red;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .style-somme-th-ttc {
    font-family: 'Montserrat', sans-serif;
    background-color: #d0e4f5;
    position: absolute;
    left: 1px;
    // bottom: 0px;
    width: auto;
    height: 35px;
    border-radius: 30px;
    z-index: 120;
    margin-left: 33px;
  }
  .style-arrow-not-selected {
    width: 13px;
    height: 13px;
    color: #aaacb0;
    // margin-top: 3px;
    margin-left: 1px;
    margin-right: 1px;
    cursor: pointer;
  }
  .style-arrow-selected {
    width: 13px;
    height: 13px;
    color: #4d4bac;
    margin-left: 1px;
    margin-right: 1px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.table-rapport-style .customTableRefreshFactureTh td,
.customTableRefreshFactureTh th {
  width: calc(100vh / 4);
  line-height: 10px;
  span {
    cursor: pointer;
  }
}
.table-rapport-style .size-table-custom {
  width: 100%;
}
.table-rapport-style .customTableRefreshFactureTh {
  .item-details-style {
    border: 1px solid #fff;
    //  width: 15px !important;
    display: table-cell;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  .colhead-num-dossier-th,
  .col-num-dossier-th {
    left: 30px !important;
  }
  .thvalidation,
  .thvalidation {
    width: 50px !important;
  }
  td,
  th {
    font-size: 9px;
    // width: 107px;
    // min-width: 107px;
  }
  .btn-anomalie {
    padding: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  .background-week {
    justify-content: start !important;
    vertical-align: middle;
    td {
      padding: 8px 5px;
      margin: 0px;
      font-size: 9.5px;
    }

    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
  }
}
// .table-rapport-style .size-table-custom {
//   width: 100%;
// }
.loader-refresh {
  height: 50px;
  position: absolute;
  width: 100%;
  z-index: 10004;
  background: #fcfcfc63;
  height: 100%;
  .half-circle-spinner {
    margin: auto;
    margin-top: 20%;
  }
}
</style>
